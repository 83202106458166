.social-view-component {
	max-width: 700px;
	//width: 700px;
	&::-webkit-scrollbar {
		width: 0px;
		height: 0px;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--icon-neutral);
		border-radius: 3.5px;
	}

	&::-webkit-scrollbar-track-piece {
		margin: 0px;
	}
	.tweet {
		padding: 10px;
		margin-bottom: 5px;
		background: #fff;

		overflow: hidden;
	}
	.header-text {
		margin-top: 10px;
	}
	.timeline {
		flex: 2;

		.header {
			border-bottom: 1px solid rgba(255, 0, 0, 0.1);
		}

		.avatar {
			min-width: 50px;
			height: 50px;
			border-radius: 50%;
		}

		.tweet-box {
			display: flex;
			.tweet-textarea {
				flex-grow: 100;
				border: none;
				padding: 10px;

				font-size: 18px;
			}
		}

		.tweets {
			border-top: 1px solid rgba(255, 0, 0, 0.1);

			.tweet {
				padding: 10px;
				display: flex;
				border-bottom: 1px solid rgba(255, 0, 0, 0.1);
				max-height: 430px;
				overflow: hidden;
				.tweet-message {
					padding: 0 10px 0 10px;

					.tweet-image {
						width: 100%;
					}
				}
			}
		}
		.title-siz {
			font-size: 45px;
		}
	}

	.tab-not-selected,
	.tab-selected {
		width: 200px;

		span {
			width: 100%;
			display: block;
			height: 5px;
			background: #edeff1;
		}
		//height: 20px;
	}

	.tab-selected {
		span {
			height: 5px;
			display: block;
			background: #0053e4 !important;
		}
	}

	.write-text,
	.header-text {
		display: none !important;
	}

	.kinera-title-user {
		margin-left: 20px !important;
		font-size: 20px !important;
		font-weight: 600;
	}

	.empty-text {
		color: #000 !important;
	}
}

.overflow-items {
	max-height: 270px !important;
	overflow-y: auto;
	margin-bottom: 10px;
	::-webkit-scrollbar {
		width: 7px;
		height: 7px;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--icon-neutral);
		border-radius: 3.5px;
	}

	&::-webkit-scrollbar-track-piece {
		margin: 0px;
	}
}
