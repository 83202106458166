.table-body {
	-webkit-box-shadow: 2px 10px 20px -10px rgba(153, 148, 153, 1);
	-moz-box-shadow: 2px 10px 20px -10px rgba(153, 148, 153, 1);
	box-shadow: 2px 10px 20px -10px rgba(153, 148, 153, 1);
	border-radius: 4px;
	background: var(--gray-bg-color);
}
.scroll-list {
	overflow-y: auto;
}
.scroll-list::-webkit-scrollbar {
	width: 10px;
}

/* Track */
.scroll-list::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
.scroll-list::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
.scroll-list::-webkit-scrollbar-thumb:hover {
	background: #555;
}
