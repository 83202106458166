.edit-component {
	.cover-image-relative {
		width: 100px;
		height: 100px;
		overflow: hidden;
		border-radius: 50%;
		z-index: 100;
		position: relative;
	}

	.cover-image-bg {
		position: absolute;
		width: 100% !important;

		height: 100%;
		top: 0px;
		z-index: 0;
	}

	.cancel-button {
		&:hover {
			background: #80808011;
			color: #fff;
		}
	}
}
