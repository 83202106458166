.draggable {
	position: fixed;
	bottom: 0px;
	right: 0px;
	border: 1px solid #dedede;
	height: fit-content;
	width: fit-content;
}

.draggable-panel {
	cursor: move;
}

.draggable-content {
	padding: 15px;
}
