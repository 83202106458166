.main-video {
	iframe {
		min-height: 400px;
	}
}

.react-multi-carousel-track {
	gap: 15px !important;
}

iframe {
	width: 100%;
}

.empty-state {
	min-height: 400px;
}

.active-btn {
	background: #6f99dd;
	font-weight: 700;
	color: #ffff;
	font-weight: 7000;
	padding: 10px;
	border-radius: 3px;
}

.title-festival {
	font-size: 22px;
	//  text-decoration: underline;
}
