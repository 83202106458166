.children-absolute-bg {
	position: absolute;
	background-image: url('../../../../assets/kinera_constellations.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	margin-top: -20px;
	opacity: 0.3;
	width: 100%;
	height: 100%;
}
