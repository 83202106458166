.left-content {
	padding-left: 30px;
	background-color: rgb(21, 27, 38);
	button,
	span,
	div {
		color: #fff !important;
		&:hover {
			background: none !important;
		}
	}
	.logo {
		margin-top: 50px;
		width: 200px;
	}
	.text {
		width: 300px;
		margin-top: 30px;
	}
}

.any-to-any {
	.withScreencast {
		display: none !important;
	}
	iframe {
		h1 {
			display: none !important;
		}
	}
}

.right-content {
	.line-divider {
		width: 100%;
		height: 1px;
		background-color: #fff;
		margin: 17px 0px;
	}
}
