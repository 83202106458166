.bg-form {
	font-weight: bold;
	font-size: 16px;
	select {
		color: #000;
	}

	.save-btn {
		color: #fff !important;

		border-radius: 14px;
		&:hover {
			transition: all ease-in 0.2s;
			opacity: 0.8;
		}
	}

	.cancel-btn {
		background: linear-gradient(
			90deg,
			rgba(249, 117, 173, 1) 0%,
			rgba(254, 87, 146, 1) 51%,
			rgba(235, 40, 109, 1) 100%
		);
		color: #fff !important;

		&:hover {
			transition: all ease-in 0.2s;
			opacity: 0.8;
		}
	}

	.cover-image-relative {
		width: 100px;
		height: 100px;
		overflow: hidden;
		border-radius: 50%;
		z-index: 100;
		position: relative;
	}

	.cover-image-bg {
		position: absolute;
		width: 100% !important;

		height: 100%;
		top: 0px;
		z-index: 0;
	}
}
