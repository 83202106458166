.profile-component {
	.bg-default {
		transition: ease-in all 0.2s;
		.tab {
			transition: ease-in all 0.2s;
			background: #fff;
			padding: 10px 22px;
			color: #2727c7;
		}

		.default-selected {
			color: #fff;
			padding: 10px 22px;
			font-weight: bold;
		}

		.options-menu {
			padding: 10px 22px;
			cursor: pointer;
			transition: ease-in all 0.2s;
			font-weight: bold;
			&:hover {
				transition: ease-in all 0.2s;
				opacity: 0.8;
			}
		}
	}
	.my-profile-component {
		.cards-content {
			display: flex !important;
			align-items: center !important;
			justify-content: flex-start !important;
			margin: 10px !important;
		}
		.cards {
			width: 245px !important;
			max-width: 245px;
			//background: #fff;
		}
	}
}

.number-pend {
	padding: 10px;
	border-radius: 500px !important;
	height: 25px;
	font-size: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
}
