.page-padding {
	padding: 10px;
}

html {
	overflow-x: hidden;
}

.ql-editor {
	padding: 12px 0px !important;
	font-weight: 400 !important;
}

.pb-max {
	padding-bottom: 90px !important;
}

.soon {
	background-image: url('https://kinera.network/assets/kineraFund_kinex.50222c18.png');
	background-size: cover;
	background-position: center;
}

.main {
	height: 100vw;
	flex-direction: column;
	display: flex;
	margin-bottom: 50px;
	background-image: url('./assets/bg.png');
	background-size: cover;
	background-position: center;
}

.default-shadow {
	box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
}

.fira-mono-regular {
	font-family: 'Fira Mono', monospace;
	font-weight: 400;
	font-style: normal;
}

.fira-mono-medium {
	font-family: 'Fira Mono', monospace;
	font-weight: 500;
	font-style: normal;
}

.defaut-p {
	padding: 6px 10px;
}

.rtl-text {
	direction: rtl;
	text-align: right;
}

.fira-mono-bold {
	font-family: 'Fira Mono', monospace;
	//	font-weight: 700;
	font-style: normal;
}

.font-mono {
	font-family: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono', 'Roboto Mono', 'Oxygen Mono',
		'Ubuntu Monospace', 'Source Code Pro', 'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;
}

.shadow-t {
	box-shadow: 0px 0px 9px 12px rgba(0, 0, 0, 0.1);
}

.plus-border-style {
	border: 3px;
	border-style: dashed;
	border-radius: 117%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 4px;
}

@font-face {
	font-family: 'Circular Std';
	src: url('./circular-std-medium-500.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

.truncate-text {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	word-break: break-word;
}

.text-truncate-three {
	word-wrap: break-word;
	white-space: break-spaces;
}

body {
	font-family: 'Circular Std', sans-serif;
}

.has-error {
	border: 2px solid var(--border-critical) !important;
}

h1 {
	font-family: 'Fira Mono', monospace;
}

.default-text {
	color: var(--text-color);
}

.MuiModal-root {
	z-index: 9999999999999 !important;
}

:root {
	--gray-bg-color: #ededed;
	--black-bg-color: #000;
	--text-color: #292929;
	--text-black: #000;
	--text-white: #ffff;
	--pink-bg-color: #d090dc;
	--red-collor: #ef4444;
	--primary: #0053e4;
	--secondary: #60a5fa;
	--critical: #ea6d6f;
	--success: #78cf9f;
	--warning: #f1ca45;
	// background
	--background-default: #eceff1;
	--background-shadow: #191c32;
	// surface
	--surface-default: #ffffff;
	--surface-neutral: #f4f8fb;
	--surface-primary: #ebf0fc;
	--surface-secondary: #60a5fa;
	--surface-critical: #fdeff0;
	--surface-success: #e4f5ec;
	--surface-warning: #fcf4da;
	--surface-off: #f7f6f6;
	// action
	--action-primary: #0053e4;
	--action-primary-light: #7ea5f5;
	--action-primary-dark: #003eab;
	--action-secondary: #60a5fa;
	--action-secondary-light: #fda4e1;
	--action-secondary-dark: #bf3b96;
	--action-critical: #ea6d6f;
	--action-critical-light: #f3b4b5;
	--action-critical-dark: #af5253;
	--action-warning: #f1ca45;
	--action-warning-light: #f6e3a0;
	--action-warning-dark: #d3b13c;
	--action-success: #78cf9f;
	--action-on: #ffffff;
	--action-off: #c3ccd5;
	--action-disable: #c3ccd5;
	--action-dark: #1c222b;
	// text
	--text-default: #233043;
	--text-sub: #848b94;
	--text-primary: #0053e4;
	--text-primary-light: #7ea5f5;
	--text-primary-dark: #003eab;
	--text-secondary: #60a5fa;
	--text-critical: #ea6d6f;
	--text-success: #78cf9f;
	--text-warning: #f1ca45;
	--text-disable: #d3d3d3;
	--text-on: #ffffff;
	// icons
	--icon-default: #233043;
	--icon-neutral: #828b95;
	--icon-primary: #0053e4;
	--icon-primary-light: #7ea5f5;
	--icon-primary-dark: #003eab;
	--icon-secondary: #60a5fa;
	--icon-critical: #ea6d6f;
	--icon-success: #78cf9f;
	--icon-warning: #f1ca45;
	--icon-disable: #dddddd;
	--icon-on: #ffffff;
	// borders
	--border-default: #edeff1;
	--border-neutral: #f4f8fb;
	--border-primary: #0053e4;
	--border-primary-light: #7ea5f5;
	--border-primary-dark: #003eab;
	--border-secondary: #60a5fa;
	--border-critical: #ea6d6f;
	--border-success: #78cf9f;
	--border-warning: #f1ca45;
	--border-disable: #dddddd;
	// z-index
	// --z-index-mask: 900;
	// --z-index-dropdown: 1000;
	--z-index-sticky: 1020;
	--z-index-fixed: 1030;
	--z-index-modal-backdrop: 1040;
	--z-index-offcanvas: 1050;
	--z-index-modal: 1060;
	--z-index-mask: 1061;
	--z-index-dropdown: 999999999;
	--z-index-popover: 1070;
	--z-index-tooltip: 1080;
	--z-index-lazy-load: 1090;
	// border
	--border-radius: 4px;
	// padding
	--padding-default: 16px;
	--padding-row: 24px;
	// transition
	--transition-slow: all ease-out 1s;
	--transition-default: all ease-out 0.6s;
	--transition-fast: all ease-out 0.2s;
	--transition-second-slow: 0.3s;
	--transition-second-default: 0.6s;
	--transition-second-fast: 1s;
	// fonts
	--font-circular-std: 'Circular Std';
	--font-circular-std-book: 'Circular Std Book';
}
.custom-scroll ::-webkit-scrollbar {
	width: 1px;
	height: 1px;
}

.custom-scroll ::-webkit-scrollbar-thumb {
	background: #000; /* Cor preta */
	border-radius: 0; /* Cantos retos */
}

.custom-scroll ::-webkit-scrollbar-track {
	background: transparent; /* Faz o track ser invisível */
}

.custom-scroll {
	-ms-overflow-style: none; /* Para IE e Edge */
	scrollbar-width: thin; /* Para Firefox */
	scrollbar-color: #000 transparent; /* Thumb preto, track transparente */
}
.hidden-scroll::-webkit-scrollbar {
	width: 0;
	height: 0;
	background: transparent; /* or any color you want */
}

.hidden-scroll {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.material-symbols-outlined {
	font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
}

.ql-toolbar {
	display: none !important;
	padding: 0px !important;
	order: 2;
	position: relative;
	border: none !important;
}

:root {
	--primary-glow: radial-gradient(#e6007aaa, rgba(1, 65, 255, 0));
	--secondary-glow: linear-gradient(to bottom right, rgba(1, 65, 255, 0), rgba(1, 65, 255, 0), rgba(1, 65, 255, 0.3));
}

.gray-color {
	color: rgb(36 38 39 / 80%);
}

.peer-video {
	height: 250px;
	width: 450px;
	border-radius: 0.5rem;
	object-fit: cover;

	border: 1px solid #191b23;
}

/*
  .peer-video {
	height: 250px;
	width: 450px;
	border-radius: 0.5rem;
	object-fit: cover;
	margin: 10px;
	border: 1px solid #191B23;
  }
  
  .video-cover {
	position: absolute;
	top: 0;
	left: 0;
	background-color: #191B23;
	display: flex;
	align-items: center;
	justify-content: center;
  }
  */

.control-bar > *:not(:first-child) {
	margin-left: 8px;
}

.hide {
	display: none;
}

.separator {
	font-size: 12px;
}

.center {
	align-items: center;
}

.highlight {
	background: #2e3038;
}

.conference-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: white;
}

.peers-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 1rem;
}

.viewers-count {
	margin-top: 1rem;
}

.chat-section {
	width: 300px;
	background: #333;
	padding: 1rem;
	border-radius: 8px;
	margin-top: 1rem;
}

.messages {
	max-height: 200px;
	overflow-y: auto;
	margin-bottom: 1rem;
}

.message {
	padding: 0.5rem;
	border-bottom: 1px solid #444;
}

.chat-input {
	display: flex;
}

.chat-input input {
	flex: 1;
	padding: 0.5rem;
	border: none;
	border-radius: 4px 0 0 4px;
}

.chat-input button {
	padding: 0.5rem;
	border: none;
	background: #0066ff;
	color: white;
	border-radius: 0 4px 4px 0;
	cursor: pointer;
}

.chat-input button:hover {
	background: #0055cc;
}
