.header-title {
	//color: #fff;
	font-weight: 600;
	font-size: 23px;
	padding: 10px;
	border-radius: 4px;
}

.abs-btn {
	position: absolute;
	top: 16%;
	right: 0%;
	transform: translate(-50%, -50%);
}

.main-video {
	iframe {
		width: 800px;
	}
}
.swal2-popup {
	min-width: 1000px;
}

.votes-style {
	margin-bottom: 10px;
	font-weight: 700;
	font-style: revert;
	font-family: math;
	font-size: 26px;
}
