.absolute-container-images {
	position: absolute;
	bottom: 0 !important;
	right: 33% !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
	background: rgba(255, 255, 255, 0.35);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(13.5px);
	-webkit-backdrop-filter: blur(13.5px);
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
