.card {
	background-color: var(--c-background-primary);
	box-shadow: 0 3px 3px 0 rgba(#000, 0.05), 0 5px 15px 0 rgba(#000, 0.05);
	border-radius: 8px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.text-small {
	font-size: 12px;
}

.current-movie {
	overflow: hidden;
	img {
		margin-top: 2px;
		width: 100px !important;
		height: 95px !important;
	}
}

.festival {
	iframe,
	video {
		width: 100%;
		min-height: 200px;
	}
}

.card-header {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 1.5rem 1.25rem 1rem 1.25rem;
	div {
		display: flex;
		align-items: center;

		span {
			width: 40px;
			height: 40px;
			border-radius: 8px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			img {
				// max-width: 85%;
				max-height: 100%;
			}
		}

		h3 {
			margin-left: 0.75rem;
			font-weight: 500;
		}
	}
}

.toggle {
	p {
		display: block;
		width: 140px;
		height: 24px;
		text-align: center;
		font-weight: bold;
		color: #fff;
		border-radius: 99em;

		box-shadow: inset 1px 1px 1px 0 rgba(#000, 0.05);
		position: relative;
		transition: 0.15s ease;
	}
}

.card-body {
	border-radius: 4px;
	padding: 1rem 1.25rem;
	font-size: 0.875rem;
}

.card-footer {
	margin-top: auto;
	padding: 5px;
	display: flex;
	align-items: center;
	//justify-content: flex-end;
	border-top: 1px solid var(--c-border-primary);
	a {
		color: var(--c-text-action);
		text-decoration: none;
		font-weight: 500;
		font-size: 0.875rem;
	}
}

html {
	&::-webkit-scrollbar {
		width: 12px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--c-text-primary);
		border: 4px solid var(--c-background-primary);
		border-radius: 99em;
	}
}

.sub-img {
	width: 75px !important;
	height: 50px !important;
}

.gapped {
	height: 100%;
	gap: 20px;
	display: flex;
	flex-direction: column;
}

.break-text {
	overflow: hidden !important;
	display: -webkit-box !important;
	max-height: 45px !important;
	height: 45px;
	-webkit-line-clamp: 2 !important; /* Número de linhas que você quer mostrar antes de truncar */
	-webkit-box-orient: vertical !important;
	text-overflow: ellipsis !important;
}
