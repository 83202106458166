.view-box {
	cursor: pointer;
	.ql-container {
		border: none !important;
		cursor: pointer;
	}
	.ql-snow {
		cursor: pointer;
		border: none !important;
	}
	border: none !important;
	.quill {
		font-weight: 550;
		cursor: pointer;
		&:hover {
			opacity: 0.7;
			cursor: pointer;
		}

		&::-webkit-scrollbar {
			width: 5px;
			height: 5px;
		}
		&::-webkit-scrollbar-thumb {
			background-color: var(--icon-neutral);
			border-radius: 3.5px;
		}

		&::-webkit-scrollbar-track-piece {
			margin: 0px;
		}
	}
}
