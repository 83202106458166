.button {
	border-radius: 4px;
	&__action {
		padding: 2px;

		width: 100%;
		height: 100%;
	}
	&[disabled] {
		//background: red !important;
		cursor: not-allowed; /* Opcional: Muda o cursor para indicar que está desativado */
	}

	&__action[disabled] {
		background: var(--gray-bg-color);
	}
}
