.body-color {
	background-image: url('../../assets/header.png');
	background-size: cover;
	box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
}

.border-default {
	border: solid 1px #e1dddd;
}

.body-items {
	.body-drop {
		list-style-type: none;
		background-color: #ffffff;
		background-clip: padding-box;
		border-radius: 8px;
		outline: none;
		box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
	}
	&::-webkit-scrollbar {
		width: 10px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background-color: rgb(241, 241, 241); /* Lighter gray color */
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background-color: rgb(136, 136, 136); /* Light gray color */
		border-radius: 10px; /* Rounded corners on the scrollbar thumb */
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
}
