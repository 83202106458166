.social-post {
	width: 700px;
	max-width: 700px;
	.ql-container {
		border: none !important;
	}
	.ql-snow {
		border: none !important;
	}
	.quill {
		border: none !important;
		height: 100%;
		justify-content: space-between;
		display: flex;
		flex-direction: column;
		.ql-toolbar {
			display: none !important;
			padding: 0px !important;
			order: 2;
			position: relative;
			border: none !important;
		}
	}
}
