.md-view {
	font-size: 22px;
	&:hover {
		font-size: 23px;
		color: rgb(80, 80, 192);
	}
}

.card-movie-bg {
	// background-image: url('../../assets/strk.gif');
	background-size: cover;
	background: #ececee;
	padding: 5px;
	background-position: center;
}

.cards-hover {
	transition: transform 0.3s ease;
	&:hover {
		transform: translateY(-2px);
	}
}

.view-cc {
	width: fit-content;
	display: block;
	position: relative;
	border-radius: 11px;
	padding: 2px;
	min-width: 40px;
	text-align: center;
	color: #ffff;
}

.tags {
	.tag {
		font-size: 11px;
		color: #fff;
		&:hover {
			cursor: pointer;
			text-decoration: underline;
		}
	}
}

.tags::-webkit-scrollbar {
	width: 10px;
	height: 5px;
}

.tags::-webkit-scrollbar-thumb {
	background-color: #401c42 !important;
	border-radius: 20px;
}

.tags::-webkit-scrollbar-track {
	background-color: transparent; /* Cor do fundo da barra de rolagem no WebKit (Chrome, Safari) */
}
