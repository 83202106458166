.dsc-badge {
	font-weight: 600;
	border: none;
	padding: 2px 8px;

	text-align: center;
	cursor: pointer;
	border-radius: 12px;
	transition: all 300ms ease 0s;
}

.dsc-tab {
	font-weight: 400;
	border: none;
	padding: 2px 8px;
	text-align: center;
	cursor: pointer;
	border-radius: 12px;
	transition: all 300ms ease 0s;
	.tab-selected {
		// width: 50% !important;
		//max-width: 50% !important;
		//margin: auto;
	}
}
