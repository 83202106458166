.chat-component {
	height: 90%;

	.h-total {
		height: 100%;
	}

	.box-message {
		input {
			padding: 10px;
			border-radius: 50px;
		}
		.arrow-btn {
			overflow: hidden;
			border-radius: 100px;
		}
	}

	.padding-message {
		// min-width: 150px;
		padding: 4px;

		.under-message {
			margin: 0px 8px;
		}
	}

	.chat-body {
		height: 580px;
		overflow-y: auto;
		padding: 10px;
	}
	.chat-body::-webkit-scrollbar {
		width: 3px; /* Largura da barra de rolagem */
	}

	.chat-body::-webkit-scrollbar-thumb {
		background-color: #888; /* Cor do polegar (thumb) */
		border-radius: 10px; /* Arredondamento do thumb */
	}

	.chat-body::-webkit-scrollbar-track {
		background-color: #f1f1f1; /* Cor do track (trilho da scrollbar) */
	}
}
