.calendar-body {
	.calendar-number {
		background: #000;
		color: #fff;
		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 2px;
		justify-content: center;
		font-weight: bold;
		border-radius: 4px;
	}
}
