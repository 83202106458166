.modal-btn {
	.modal-confirm-btn {
		padding: 4px;

		color: #000;
		font-weight: 600;
	}
	.modal-close-btn {
		padding: 4px;
		background: #911b1b;
		color: #fff;
		font-weight: 600;
	}
}
@keyframes pulse {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.02);
	}
	100% {
		transform: scale(1);
	}
}

.pulse-animation {
	animation: pulse 1s infinite;
}

.create-constelation {
	.tags-mpaed {
		padding: 2px;
		cursor: pointer;
		.tag-title {
			cursor: pointer;
			font-weight: 700;
			font-size: 15px;
		}

		.sub-tag {
			font-size: 13px;
			color: rgb(39, 39, 110);
		}

		&:hover {
			.sub-tag {
				opacity: 0.5;
			}
			.tag-title {
				text-decoration: underline;
				color: blue;
			}
		}
	}

	.cat-father {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		border-radius: 4px;
		gap: 10px;
		background: #fff;

		.tag-children {
			display: flex;
			gap: 10px;
			//flex-grow: 1;
		}
	}

	.small-text {
		font-size: 11px;
		font-weight: 700;
		margin-block: 0px;
		margin-bottom: 10px;
	}

	.define-text {
		text-decoration: underline;
	}
}
