.modal-body {
	&::-webkit-scrollbar {
		width: 0px;
		height: 0px;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--icon-neutral);
		border-radius: 3.5px;
	}

	&::-webkit-scrollbar-track-piece {
		margin: 0px;
	}
}
