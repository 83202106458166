.community-proposal {
	.Voting {
		background: blue;
		padding: 2px 10px;
		color: #fff;
		border-radius: 21px;
	}

	.Reject {
		background: #f53c3c;
		padding: 2px 10px;
		color: #fff;
		border-radius: 21px;
	}
	.calendar-body {
		.calendar-number {
			background: #000;
			color: #fff;
			display: flex;
			align-items: center;
			flex-direction: column;
			padding: 2px;
			justify-content: center;
			font-weight: bold;
			border-radius: 4px;
		}
	}
}

.community-body-img {
	position: absolute;
	background-image: url('../../assets/kinera_communities.png');
	background-position: center;
	background-size: cover;
	height: 100%;
	width: 100%;
	opacity: 0.2;
	left: 0;
	bottom: 0;
	z-index: 0;
}

.approve-block {
	background: #2ed47a;
	height: 20px;
	width: 20px;
}

.reject-block {
	background: #f53c3c;
	height: 20px;
	width: 20px;
}

.votes-container {
	padding: 5px;
	.Approve {
		background: #2ed47a;
		padding: 2px 5px;
		color: #fff;
		border-radius: 4px;
	}
	.Reject {
		border-radius: 4px;
		background: #f53c3c;
		padding: 2px 5px;
		color: #fff;
	}
	.not-selected {
		padding: 2px 5px;
		color: #9a9a9a;
	}
}
