.prodution-tools-component {
	.input-style {
		// background: #ececee;

		opacity: 1;

		textarea {
			height: 100%;
			width: 100%;
		}
	}
}
