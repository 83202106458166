.form {
	margin-top: 20px;
	width: 100%;
	gap: 20px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	.padding-btn {
		padding: 16px 24px 24px;
	}
}
