.config-form {
	font-weight: bold;
	font-size: 16px;
}
.config-btn {
	background: linear-gradient(90deg, rgba(111, 33, 108, 1) 0%, rgba(101, 24, 106, 1) 51%, rgba(30, 2, 45, 1) 100%);
	&:hover {
		transition: all ease-in 0.2s;
		opacity: 0.8;
	}
}

.select-account {
	padding: 8px;
	border: solid 2px #ededed;
	border-radius: 4px;

	&:active {
		border-color: red;
	}

	&:focus {
		border-color: blue;
	}

	option {
		padding: 8px;
		border: solid 2px #ededed;
	}
}
