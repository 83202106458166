.create-post-box {
	.ql-container {
		border: none !important;
	}
	.ql-snow {
		border: none !important;
	}
	.quill {
		border: none !important;
		height: 100%;
		justify-content: space-between;
		display: flex;
		flex-direction: column;
		.ql-toolbar {
			display: none !important;
			padding: 0px !important;
			order: 2;
			position: relative;
			border: none !important;
		}
	}
	.box-quill::-webkit-scrollbar {
		border: none !important;
		width: 0;
		height: 0;
		background: transparent; /* or any color you want */
	}

	.box-quill {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
}
.box-quill {
	background: #eaeaea;
	border-radius: 8px;
	padding: 10px;
}
.ql-container {
	border: none !important;
}
.ql-snow {
	border: none !important;
}
