.kinergy-home {
	@keyframes pulse {
		0% {
			transform: scale(1);
		}
		50% {
			transform: scale(1.02);
		}
		100% {
			transform: scale(1);
		}
	}

	.quill {
		overflow-y: auto !important;
		max-height: 280px;
	}

	.pulse-animation {
		animation: pulse 1s infinite;
	}
	.title-categorie {
		margin-left: 10px;
		background: linear-gradient(90deg, rgba(59, 16, 16, 1) 0%, rgba(101, 24, 106, 1) 51%, rgba(30, 2, 45, 1) 100%);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-size: 22px;
		line-height: 1.25;
		font-weight: 900;
		letter-spacing: -1.5px;
	}
}

.fit-image {
	height: 100%;
	//border: solid 1px #ededed;
	object-fit: cover;
	border-radius: 10px;
}

.tag {
	box-shadow: none !important;
	white-space: nowrap;
	margin-bottom: 5px;
}

.tags-container {
	width: 100%;
	overflow-x: auto;
	scrollbar-width: thin;
	scrollbar-color: #401c42 transparent;
}

.tags-container::-webkit-scrollbar {
	width: 2px;
	height: 2px;
}

.tags-container::-webkit-scrollbar-thumb {
	background-color: #401c42 !important;
	border-radius: 20px;
}

.tags-container::-webkit-scrollbar-track {
	background-color: transparent; /* Cor do fundo da barra de rolagem no WebKit (Chrome, Safari) */
}

.shodow-items {
	background: linear-gradient(90deg, rgb(64, 28, 66) 0%, rgb(36, 13, 25) 51%, rgb(57, 24, 60) 100%);
	box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
	position: relative;
	// z-index: 10000;
	color: white;
}

.home-posts {
	padding: 5px;
	.tweet {
		padding: 10px;
		margin-bottom: 5px;
		background: #fff;

		overflow: hidden;
	}
	.header-text {
		margin-top: 10px;
	}
}
