fieldset {
	border: none !important;
}

svg {
	color: #fff;
}

.dropdown-body {
	background: #e5e7eb !important;
}
