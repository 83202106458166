.moderation {
	.default-text {
		font-weight: 600;
	}
	.Mui-selected {
		border-bottom: 5px solid;
	}
}
.dark {
	background: #1e293b;
	color: #fff;
}
