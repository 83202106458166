.dsc__text {
	&__h1 {
		font-weight: 600;
		font-size: 60px;
	}
	&_h3 {
		font-weight: 600;
		font-size: 20px;
	}
}
